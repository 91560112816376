/* global gtag */
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

history.listen((location) => {
  if (gtag) {
    gtag('config', 'UA-143519072-2', { page_path: location.pathname + location.search });
  }
});

export default history;
