import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export const fragmentsMetric = {
  metric: gql`
    fragment metricFields on Metric {
      id
      label
    }
  `,
};

export const createTeamMetricGraphQL = graphql(
  gql`
    mutation createTeamMetric($label: String!, $teamId: ID!) {
      createMetric(label: $label, teamId: $teamId) {
        ...metricFields
      }
    }
    ${fragmentsMetric.metric}
  `,
  { name: 'createTeamMetricGraphQL' },
);

export const updateTeamMetricGraphQL = graphql(
  gql`
    mutation updateTeamMetric($id: ID!, $label: String!, $teamId: ID!) {
      updateMetric(id: $id, label: $label, teamId: $teamId) {
        ...metricFields
      }
    }
    ${fragmentsMetric.metric}
  `,
  { name: 'updateTeamMetricGraphQL' },
);

export const getMetricsGraphQL = graphql(
  gql`
    query getMetrics($teamId: ID!) {
      allMetrics(filter: {
        OR: [{
          team: { id: $teamId }
        }, {
          team: null
        }]
      }) {
        ...metricFields
        team {
          id
        }
      }
    }
    ${fragmentsMetric.metric}
  `,
  {
    name: 'getMetricsGraphQL',
    options: props => ({
      variables: { teamId: props.user.team.id },
    }),
    props: ({ getMetricsGraphQL: { allMetrics: metrics, loading } }) => ({
      metrics,
      loading,
    }),
  },
);

export const updateOMTMGraphQL = graphql(
  gql`
    mutation updateOMTM($teamOMTMId: ID!, $id: ID!) {
      updateMetric(id: $id, teamOMTMId: $teamOMTMId) {
        ...metricFields
      }
    }
    ${fragmentsMetric.metric}
  `,
  { name: 'updateOMTM' },
);
