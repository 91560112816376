import { signInSuccessHandler, signOutHandler } from '../../utils/authHelpers';

export const SIGN_IN_REQUEST = 'AccountActions/signInRequest';
export const SIGN_IN_SUCCESS = 'AccountActions/signInSuccess';
export const SIGN_IN_FAILED = 'AccountActions/signInFail';
export const SIGN_OUT = 'AccountActions/signOut';

export function signInRequest() {
  return {
    type: SIGN_IN_REQUEST,
  };
}

export function signInSuccess(token) {
  return signInSuccessHandler({
    type: SIGN_IN_SUCCESS,
    token,
  });
}

export function signInFail(error) {
  return {
    type: SIGN_IN_FAILED,
    error,
  };
}

export const signOut = () => signOutHandler({
  type: SIGN_OUT,
});
