import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export const fragmentsTag = {
  tag: gql`
    fragment tagFields on Tag {
      id
      value
    }
  `,
};

export const getAllTeamTagsGraphQl = graphql(
  gql`
    query getAllTeamTags($teamId: ID!){
      allTags(filter: {
        team: { id: $teamId },
      }) {
        ...tagFields
      }
    }
    ${fragmentsTag.tag}
  `, {
    options: props => ({
      variables: {
        teamId: props.user.team.id,
      },
    }),
    props: ({ data: { loading, allTags } }) => ({ allTags, loading }),
  },
);

export const createTagGraphQL = graphql(
  gql`
    mutation createTag(
    $value: String!
    $id: ID!
    $teamId: ID!
    ){
      createTag(
      value: $value
      ideaId: $id
      teamId: $teamId
      ) {
        ...tagFields
      }
    }
    ${fragmentsTag.tag}
  `,
  { name: 'createTag' },
);

export const liveEditCreateTagGraphQL = graphql(
  gql`
    mutation createTag(
    $value: String!
    $ideaId: ID!
    $teamId: ID!
    ){
      createTag(
      value: $value
      ideaId: $ideaId
      teamId: $teamId
      ) {
        id
      }
    }
  `,
  { name: 'createTag' },
);

export const deleteTagGraphQL = graphql(
  gql`
    mutation deleteTag (
      $id: ID!
    ) {
      deleteTag (
        id: $id
      ) {
        id
      }
    }
  `,
  { name: 'deleteTag' },
);

export const GET_TAG_BY_NAME = gql`
    query getTagIdByName (
      $ideaId: ID!
      $value: String
    ) {
      allTags ( filter: {value: $value, idea: {id: $ideaId} }) {
        id
      }
    }
  `;

export const getTagsFromIdeaGraphQL = graphql(
  gql`
    query getTagsFromIdea (
      $ideaId: ID!
    ) {
      allTags (
        filter: {
          idea: {id: $ideaId}
        }
        orderBy: value_ASC
      ) {
        id
        value
      }
    }
  `,
  {
    options: props => ({
      variables: { ideaId: props.ideaId },
    }),
    props: ({
      data: {
        allTags, loading, error, refetch,
      },
    }) => ({
      loading, error, allTags, refetch,
    }),
  },
);

export const liveEditGetTagsFromIdeaGraphQL = graphql(
  gql`
    query liveEditGetTagsFromIdea (
      $ideaId: ID!
    ) {
      allTags (
        filter: {
          idea: {id: $ideaId}
        }
        orderBy: value_ASC
      ) {
        id
        value
      }
    }
  `,
  {
    options: props => ({
      fetchPolicy: 'network-only',
      variables: { ideaId: props.ideaId },
    }),
    props: ({
      data: {
        allTags, loading, error, refetch,
      },
    }) => ({
      loading, error, allTags, refetch,
    }),
  },
);
