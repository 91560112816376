import ApolloClient from 'apollo-client/ApolloClient';
import { getAllGrowthMetricsClient } from '../graphql/GrowthMetricAPI';
import {createUploadLink} from 'apollo-upload-client/lib';
import config from '../config';
import {setContext} from 'apollo-link-context';
import {getCookie} from './cookiesHelpers';
import {InMemoryCache} from 'apollo-cache-inmemory';

const httpLink = createUploadLink({
  uri: config.uri,
  credentials: 'same-origin',
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const graphcoolToken = getCookie('graphcoolToken');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: graphcoolToken ? `Bearer ${graphcoolToken}` : '',
    },
  };
});

const link = authLink.concat(httpLink);

const cache = new InMemoryCache({
  dataIdFromObject: o => o.id,
  addTypename: true,
});

cache.writeData({
  data: {
    growthMetricsClient: [],
  },
});

export const client = new ApolloClient({
  link,
  cache: cache.restore(window.__APOLLO_STATE__), // eslint-disable-line no-underscore-dangle
  connectToDevTools: true,
  queryDeduplication: true,
  resolvers: {
    Query: {
      growthMetricClient: (launch, _args, { cache }) => {
        const { growthMetricsClient } = cache.readQuery({ query: getAllGrowthMetricsClient });
        return growthMetricsClient.find(({ label }) => label === _args.label);
      },
      growthMetricClientById: (launch, _args, { cache }) => {
        const { growthMetricsClient } = cache.readQuery({ query: getAllGrowthMetricsClient });
        return growthMetricsClient.find(({ id }) => id === _args.id);
      },
    },
  },
});
