import Auth0Lock from 'auth0-lock';
import { setCookie, removeCookie } from './cookiesHelpers';
import config from '../config';

export function signInSuccessHandler({ type, token }) {
  const nextMonth = new Date(Date.now() + (1000 * 60 * 60 * 24 * 30));

  setCookie({
    sKey: 'graphcoolToken',
    sValue: token,
    vEnd: nextMonth,
    sPath: '/',
  });

  window.location.href = '/';

  return {
    type,
  };
}

export function signOutHandler({ type }) {
  removeCookie({ sKey: 'graphcoolToken' });

  window.location.href = '/';

  return {
    type,
  };
}

export const createAuth0UserInDB = async (
  {
    createTeam,
    idToken,
    signInAuth0,
    createUserProfile,
    picture,
    signInSuccessLocal,
    name,
    createAuth0User,
    email,
    signInFailLocal,
    deleteDraftTeam,
  },
) => {
  const team = JSON.parse(window.localStorage.getItem('invite'));
  let teamId = !team ? null : team.team.id;
  let draftTeamId = null;

  if (!teamId) {
    const { data: { createTeam: createdTeam } } = await createTeam({ variables: { name: '' } });
    teamId = createdTeam.id;
    draftTeamId = createdTeam.id;
  }

  try {
    await createAuth0User({
      variables: {
        idToken,
        name,
        teamId,
        role: 'owner',
      },
    });
  } catch (e) {
    console.log(e);

    await deleteDraftTeam({ variables: { teamId: draftTeamId } });

    signInFailLocal(e);
    return;
  }

  const { data: { signinUser: { token, user: { id: userId } } } } = await signInAuth0({ variables: { idToken } });

  await createUserProfile({ variables: { userId, avatar: picture, email } });

  signInSuccessLocal({ token });
};

export const initAuth0Lock = () => new Auth0Lock(config.client_id, config.auth0_domain, {
  auth: {
    responseType: 'token id_token',
    redirectUrl: config.redirectUrl,
  },
});

export const lockShow = lock => lock.show();

const removeInviteFromDB = async ({ removeInvite }) => {
  let inviteId = null;
  const invite = JSON.parse(window.localStorage.getItem('invite'));

  if (invite) {
    inviteId = invite.id;
  }

  if (inviteId) {
    await removeInvite({ variables: { inviteId } });
  }
};

export const signUpUserFromAuth0 = async (
  {
    lock,
    removeInvite,
    signInAuth0,
    createTeam,
    createUserProfile,
    signInSuccessLocal,
    createAuth0User,
    signInFailLocal,
    deleteDraftTeam,
  },
) => {
  await removeInviteFromDB({ removeInvite });

  lock.on('authenticated', (authResult) => {
    lock.getUserInfo(authResult.accessToken, async (error, profile) => {
      if (error) {
        console.log(error);
        return;
      }
      await createAuth0UserInDB({
        createAuth0User,
        createTeam,
        name: profile.name,
        idToken: authResult.idToken,
        signInAuth0,
        createUserProfile,
        picture: profile.picture,
        email: profile.email,
        signInSuccessLocal,
        signInFailLocal,
        deleteDraftTeam,
      });
    });
  });
};

export const signInUserFromAuth0 = (
  {
    lock,
    signInAuth0,
    signInSuccessLocal,
    signInFailLocal,
  },
) => {
  lock.on('authenticated', async (authResult) => {
    try {
      const { data: { signinUser: { token } } } = await signInAuth0({ variables: { idToken: authResult.idToken } });
      signInSuccessLocal({ token });
    } catch (e) {
      console.log(e);
      signInFailLocal(e);
    }
  });
};

export const authorizationFromAuth0 = async (
  {
    lock,
    signInAuth0,
    signInSuccessLocal,
    removeInvite,
    createTeam,
    createUserProfile,
    createAuth0User,
    history,
    deleteDraftTeam,
  },
) => {
  lock.on('authenticated', async (authResult) => {
    lock.getUserInfo(authResult.accessToken, async (error, profile) => {
      if (error) return;

      try {
        const { data: { signinUser: { token } } } = await signInAuth0({ variables: { idToken: authResult.idToken } });
        signInSuccessLocal({ token });
      } catch (e) {
        const invite = JSON.parse(window.localStorage.getItem('invite'));
        const accessCode = JSON.parse(window.localStorage.getItem('accessCode'));

        if (!(invite || accessCode)) {
          history.push('/auth/sign-up');
          return;
        }

        await removeInviteFromDB({ removeInvite });
        await createAuth0UserInDB({
          createAuth0User,
          createTeam,
          name: profile.name,
          idToken: authResult.idToken,
          signInAuth0,
          createUserProfile,
          picture: profile.picture,
          email: profile.email,
          signInSuccessLocal,
          deleteDraftTeam,
        });
      }
    });
  });
};

export default {
  signInSuccessHandler,
  signOutHandler,
};


export const slackAuthRequest = async (code) => {
  try {
    const response = await fetch(config.slack.oauthUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: `client_id=${config.slack.client_id}&client_secret=${config.slack.client_secret}&code=${code}`,
    });

    return await response.json();
  } catch (e) {
    console.error(e);
    return e;
  }
  return null;
};
