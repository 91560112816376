import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { fragmentsGrowthMetric } from './GrowthMetricAPI';
import { fragmentsCheckList } from './CheckListsAPI';
import { fragmentsTag } from './TagsAPI';
import { fragmentsMetric } from './MetricAPI';
import { fragmentsCustomerPersona } from './CustomerPersonaAPI';
import { pageRange } from '../utils/constants';

export const fragmentsIdea = {
  idea: gql`
    fragment ideaFields on Idea {
      id
      result
      learning
      createdAt
      lever
      name
      status
      due
      start
      description
      objective
      index
      learningAt
    }
  `,
  ratingICE: gql`
    fragment ratingICEFields on RatingICE {
      id
      impact
      confidence
      ease
    }
  `,
  ratingTime: gql`
    fragment ratingTimeFields on RatingTime {
      id
      factTime
      planTime
      idea {
        id
      }
    }
  `,
  assignee: gql`
    fragment assigneeFields on User {
      id
      email
      name
      profile {
        id
        email
        avatar
      }
    }
  `,
  ideaAllFields() {
    return gql`
      fragment ideaAllFields on Idea {
        ...ideaFields
        persona {
          id
          answers {
            ...answerFields
            questions {
              ...questionFields
            }
          }
        }
        tags {
          ...tagFields
        }
        growthMetric {
          ...growthMetricFields
        }
        metric {
          ...metricFields
        }
        assignee {
          ...assigneeFields
        }
        user {
          id
          email
          profile {
            id
            email
            avatar
          }
        }
        checkLists{
          ...checkListFields
          checkItems {
            ...checkItemFields
            user {
              id
              email
            }
          }
        }
        estimatedCosts
        ratingICE {
          ...ratingICEFields
        }
        ratingTime {
          ...ratingTimeFields
        }
    }
    ${this.idea}
    ${this.ratingICE}
    ${this.assignee}
    ${this.ratingTime}
    ${fragmentsMetric.metric}
    ${fragmentsTag.tag}
    ${fragmentsGrowthMetric.growthMetric}
    ${fragmentsCheckList.checkList}
    ${fragmentsCheckList.checkItem}
    ${fragmentsCustomerPersona.question}
    ${fragmentsCustomerPersona.answer}
    `;
  },
};

// used on second step in create idea
// show default experiments list
export const getExperimentsIdeasGraphQL = graphql(
  gql`
    query getExperimentsIdeas {
      allIdeas(filter: {
        team: null
      }) {
        ...ideaFields
      }
    }
    ${fragmentsIdea.idea}
  `, {
    props: ({ data: { loading, allIdeas } }) => ({ allIdeas, loading }),
  },
);

export const getIdeasCountGraphQL = graphql(
  gql`
    query getIdeasCount($status: String!, $teamId: ID!){
      _allIdeasMeta(filter: {
        AND: [{
          team: { id: $teamId }
        }, {
          status: $status
        }]
      }) {
        count
      }
    }
  `, {
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        teamId: props.user.team.id,
        status: 'idea',
      },
    }),
    props: ({ data: { _allIdeasMeta, loading } }) => ({ allIdeasMeta: _allIdeasMeta, loading }),
  },
);

// used on all pages, which used idea
// cache-and-network needed, because need to update exp list in next status when we click to promote button
export const getFilteredIdeasGraphQl = graphql(
  gql`
    query getFilteredIdeas($teamId: ID!, $status: [String!], $skip: Int, $first: Int) {
      allIdeas(
      skip: $skip
      first: $first
      filter: {
        AND: [{
          team: { id: $teamId },
          }, {
          status_in: $status
        }]
      }
      orderBy: index_DESC
      ) {
       ...ideaAllFields
      }
    }
    ${fragmentsIdea.ideaAllFields()}
  `, {
    options: (props) => {
      const searchParams = new URLSearchParams(props.location.search);
      const currPage = searchParams.get('page');

      return ({
        variables: {
          first: currPage ? pageRange : null,
          skip: currPage ? (currPage - 1) * pageRange : null,
          teamId: props.user.team.id,
          status: props.filterByStatus
            ? props.filterByStatus
            : [props.match.path.split('/')[props.match.path.split('/').length - 1]],
        },
      });
    },
    props: ({
      data: {
        loading, allIdeas, refetch, variables: { teamId },
      }, ownProps: { location: { search }, statusForView },
    }) => {
      const searchParams = new URLSearchParams(search);
      const currPage = searchParams.get('page');

      return ({
        allIdeas, allIdeasLoading: loading, currPage, refetch, teamId, statusForView,
      });
    },
  },
);

export const getAllGroupIdeasGraphQl = graphql(
  gql`
    query getAllGroupIdeas($teamId: ID!, $status: [String!]) {
      allIdeas(
      filter: {
        AND: [{
          team: { id: $teamId },
          }, {
          status_in: $status
        }]
      }
      orderBy: index_DESC
      ) {
       ...ideaAllFields
      }
    }
    ${fragmentsIdea.ideaAllFields()}
  `, {
    options: props => ({
      fetchPolicy: 'no-cache',
      variables: {
        teamId: props.user.team.id,
        status: ['test', 'plan', 'idea', 'analysis', 'learn'],
      },
    }),
  },
);

export const getAllGroupIdeasGQL = gql`
  query getAllGroupIdeas($teamId: ID!) {
    allIdeas(
    filter: {
      AND: [{
        team: { id: $teamId },
        }, {
        status_in: ["test", "plan", "idea", "analysis", "learn"]
      }]
    }
    orderBy: index_ASC
    ) {
     ...ideaAllFields
    }
  }
  ${fragmentsIdea.ideaAllFields()}
`;


export const getIdeasCountGraphQl = graphql(
  gql`
    query getIdeasCount ($teamId: ID!, $status: [String!]) {
      _allIdeasMeta(
        filter: {
          AND: [{
            team: { id: $teamId },
            }, {
            status_in: $status
          }]
        }
      ){
        count
      }
    }
  `, {
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        teamId: props.teamId,
        status: props.status,
      },
    }),
    props: ({ data: { _allIdeasMeta } }) => (
      { count: _allIdeasMeta ? _allIdeasMeta.count : null }
    ),
  },
);

export const getIdeaGraphQL = graphql(
  gql`
    query getIdea ($id: ID!) {
      Idea(id: $id) {
        ...ideaAllFields
      }
    }
    ${fragmentsIdea.ideaAllFields()}
  `,
  {
    options: props => ({
      fetchPolicy: 'cache-and-network',
      variables: { id: props.match ? props.match.params.id : props.ideaId },
    }),
    props: ({ data: { Idea, loading, error } }) => ({ loading, error, idea: Idea }),
  },
);

export const updateIdeaStatusGraphQl = graphql(
  gql`
    mutation updateIdeaStatus (
      $id: ID!
      $status: String
    ) {
      updateIdea(
        id: $id
        status: $status
       ) {
        id
      }
    }
  `, { name: 'updateIdeaStatus' },
);

export const updateIdeaKeyLearningGraphQl = graphql(
  gql`
    mutation updateIdeaKeyLearning (
      $id: ID!
      $status: String
      $learning: String
      $result: String
      $learningAt: DateTime
    ) {
      updateIdea(
        id: $id
        status: $status
        learning: $learning
        result: $result
        learningAt: $learningAt
       ) {
        ...ideaAllFields
      }
    }
     ${fragmentsIdea.ideaAllFields()}
  `, { name: 'updateIdeaKeyLearning' },
);

export const updateIdeaGraphQL = graphql(
  gql`
  mutation (
    $id: ID!
    $personaId: ID
    $description: String
    $status: String
    $name: String!
    $lever: [String!]
    $assigneeId: ID
    $teamId: ID!
    $growthMetricId: ID!
    $endDate: DateTime
    $startDate: DateTime
    $ratingICE: IdearatingICERatingICE!
  ) {
    updateIdea(
      id: $id
      personaId: $personaId
      description: $description
      name: $name
      lever: $lever
      teamId: $teamId
      assigneeId: $assigneeId
      growthMetricId: $growthMetricId
      status: $status
      start: $startDate
      due: $endDate
      ratingICE: $ratingICE
    ) {
      id
    }
  }
  `,
  { name: 'updateIdea' },
);

export const createIdeaGraphQL = graphql(
  gql`
    mutation createIdeaFromHack(
      $personaId: ID
      $description: String
      $status: String
      $index: Int
      $name: String!
      $lever: [String!]
      $assigneeId: ID
      $userId: ID!
      $teamId: ID!
      $growthMetricId: ID!
      $endDate: DateTime
      $startDate: DateTime
      $checkLists: [IdeacheckListsCheckList!]
      $ratingICE: IdearatingICERatingICE!
      $ratingTime: IdearatingTimeRatingTime
    ) {
      createIdea(
        description: $description
        name: $name
        index: $index
        personaId: $personaId
        lever: $lever
        assigneeId: $assigneeId
        userId: $userId
        teamId: $teamId
        growthMetricId: $growthMetricId
        status: $status
        start: $startDate
        due: $endDate
        checkLists: $checkLists
        ratingICE: $ratingICE
        ratingTime: $ratingTime
      ) {
        ...ideaAllFields
      }
    }
     ${fragmentsIdea.ideaAllFields()}
  `,
  { name: 'createIdea' },
);

export const quickCreateIdeaGraphQL = graphql(
  gql`
    mutation quickCreateIdea(
      $description: String
      $index: Int
      $name: String!
      $teamId: ID!
      $userId: ID!
      $tags: [IdeatagsTag!]
    ) {
      createIdea(
        description: $description
        name: $name
        status: "idea"
        teamId: $teamId
        userId: $userId
        index: $index
        tags: $tags
        ratingICE: {
          impact: 1
          confidence: 1
          ease: 1
        }
        checkLists: []
      ) {
        ...ideaAllFields
      }
    }
     ${fragmentsIdea.ideaAllFields()}
  `,
  { name: 'quickCreateIdea' },
);

export const quickCreateIdeaGQL = gql`
  mutation quickCreateIdea(
    $description: String
    $index: Int
    $name: String!
    $teamId: ID!
    $userId: ID!
    $tags: [IdeatagsTag!]
  ) {
    createIdea(
      description: $description
      name: $name
      status: "idea"
      teamId: $teamId
      userId: $userId
      index: $index
      tags: $tags
      ratingICE: {
        impact: 1
        confidence: 1
        ease: 1
      }
      checkLists: []
    ) {
      ...ideaAllFields
    }
  }
   ${fragmentsIdea.ideaAllFields()}
`;

export const removeIdeaGraphQL = graphql(
  gql`
    mutation removeIdea($ideaId: ID!) {
      deleteIdea(id: $ideaId) {
        ...ideaAllFields
      }
    }
     ${fragmentsIdea.ideaAllFields()}
  `,
  { name: 'removeIdea' },
);

export const updateIdeaRatingIceGraphQL = graphql(
  gql`
    mutation updateRatingICE(
      $id: ID!
      $impact: Int!
      $confidence: Int!
      $ease: Int!
    ){
      updateRatingICE(
        id: $id
        impact: $impact
        confidence: $confidence
        ease: $ease
      ) {
        id
      }
    }
  `,
  { name: 'updateRatingICE' },
);

export const updateOrCreateRatingTimeGraphQL = graphql(
  gql`
    mutation updateOrCreateRatingTime(
      $ratingId: ID!,
      $factTime: Float!
      $planTime: Float!
      $ideaId: ID!
    ){
      updateOrCreateRatingTime (
        create: {
          ideaId: $ideaId
          factTime: $factTime
          planTime: $planTime
        }
        update: {
          id: $ratingId
          ideaId: $ideaId
          factTime: $factTime
          planTime: $planTime
        }
      ) {
        id
      }
    }
  `,
  { name: 'updateRatingTime' },
);

export const updateOrCreateRatingTimeGQL = gql`
  mutation updateOrCreateRatingTime(
    $ratingId: ID!,
    $factTime: Float!
    $planTime: Float!
    $ideaId: ID!
  ){
    updateOrCreateRatingTime (
      create: {
        ideaId: $ideaId
        factTime: $factTime
        planTime: $planTime
      }
      update: {
        id: $ratingId
        ideaId: $ideaId
        factTime: $factTime
        planTime: $planTime
      }
    ) {
      id
    }
  }
`;


export const updateIdeaAssigneeGraphQL = graphql(
  gql`
    mutation updateIdeaAssignee (
      $ideaId: ID!
      $assigneeId: ID
    ){
      updateIdea(
        id: $ideaId
        assigneeId: $assigneeId
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaAssignee' },
);

export const updateIdeaAssigneeGQL = gql`
  mutation updateIdeaAssignee (
    $ideaId: ID!
    $assigneeId: ID
  ){
    updateIdea(
      id: $ideaId
      assigneeId: $assigneeId
    ) {
      id
    }
  }
`;

export const updateIceRatingGraphQL = graphql(
  gql`
    mutation updateIceRating (
      $ratingId: ID!
      $impact: Int!
      $ease: Int!
      $confidence: Int!
    ){
      updateRatingICE(
        id: $ratingId
        impact: $impact
        ease: $ease
        confidence: $confidence
      ) {
        id
      }
    }
  `,
  { name: 'updateIceRating' },
);

export const updateIdeaPersonaGraphQL = graphql(
  gql`
    mutation updateIdeaPersona (
      $ideaId: ID!
      $personaId: ID
    ){
      updateIdea(
        id: $ideaId
        personaId: $personaId
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaPersona' },
);

export const updateIdeaDatesGraphQL = graphql(
  gql`
    mutation updateIdeaDates (
      $ideaId: ID!
      $start: DateTime
      $due: DateTime
    ){
      updateIdea(
        id: $ideaId
        start: $start
        due: $due
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaDates' },
);

export const updateIdeaDatesGQL = gql`
  mutation updateIdeaDates (
    $ideaId: ID!
    $start: DateTime
    $due: DateTime
  ){
    updateIdea(
      id: $ideaId
      start: $start
      due: $due
    ) {
      id
    }
  }
`;

export const promoteIdeaToTestGraphQL = graphql(
  gql`
    mutation (
      $id: ID!
      $start: DateTime
      $due: DateTime
      $assigneeId: ID!
      $status: String!
      $index: Int
    ){
      updateIdea(
        id: $id
        start: $start
        assigneeId: $assigneeId
        due: $due
        status: $status
        index: $index
      ) {
        ...ideaAllFields
      }
    }
     ${fragmentsIdea.ideaAllFields()}
  `,
  { name: 'promoteIdeaToTest' },
);

export const updateIdeaIndexGraphQL = graphql(
  gql`
    mutation updateIdeaIndex (
      $id: ID!
      $index: Int!
      $status: String!
    ){
      updateIdea(
        id: $id
        index: $index
        status: $status
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaIndex' },
);


export const updateIdeaIndexGQL = gql`
  mutation updateIdeaIndex (
    $id: ID!
    $index: Int!
    $status: String!
  ){
    updateIdea(
      id: $id
      index: $index
      status: $status
    ) {
      id
    }
  }
`;

export const updateIdeaNameGraphQL = graphql(
  gql`
    mutation updateIdeaName (
      $ideaId: ID!
      $name: String!
    ){
      updateIdea(
        id: $ideaId
        name: $name
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaName' },
);

export const updateIdeaCostsGraphQL = graphql(
  gql`
    mutation updateIdeaCosts (
      $ideaId: ID!
      $costs: String
    ){
      updateIdea(
        id: $ideaId
        estimatedCosts: $costs
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaCosts' },
);

export const updateIdeaMetricGraphQL = graphql(
  gql`
    mutation updateIdeaMetric (
      $ideaId: ID!
      $metricId: ID
    ){
      updateIdea(
        id: $ideaId
        growthMetricId: $metricId
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaMetric' },
);

export const updateIdeaDescriptionGraphQL = graphql(
  gql`
    mutation updateIdeaDescription (
      $ideaId: ID!
      $description: String
    ){
      updateIdea(
        id: $ideaId
        description: $description
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaDescription' },
);

export const updateIdeaTagsGraphQl = graphql(
  gql`
    mutation updateIdeaTags(
      $ideaId: ID!
      $tags: [IdeatagsTag!]
    ) {
      updateIdea(
        id: $ideaId
        tags: $tags
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaTags' },
);

export const updateIdeaStartDateGraphQl = graphql(
  gql`
    mutation updateIdeaStartDate(
      $ideaId: ID!
      $start: DateTime
    ) {
      updateIdea(
        id: $ideaId
        start: $start
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaStartDate' },
);

export const updateIdeaDueDateGraphQl = graphql(
  gql`
    mutation updateIdeaDueDate(
      $ideaId: ID!
      $due: DateTime
    ) {
      updateIdea(
        id: $ideaId
        due: $due
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaDueDate' },
);

export const updateIdeaLevelersGraphQl = graphql(
  gql`
    mutation updateIdeaLevelers(
      $ideaId: ID!
      $levers: [String!]!
    ) {
      updateIdea(
        id: $ideaId
        lever: $levers
      ) {
        id
      }
    }
  `,
  { name: 'updateIdeaLevelers' },
);

export const getTimelineGraphQl = gql`
  query getTimelineGraph($teamId: ID!, $skip: Int, $first: Int) {
    allTimelines(
      filter: {
        team: { id: $teamId }
      }
      skip: $skip
      first: $first
      orderBy: updatedAt_DESC
    ) {
      id
      crud
      operationName
      typeId
      updatedAt
      typeName
      eventType
      additionalInfo
      team {
        id
      }
      user {
        id
        name
        email
        profile {
          id
          avatar
        }
        
      }
      message
    }
  }
`;
