export const getIdeaPositionInStateById = (state, ideaId) => {
  const position = {
    stage: null,
    index: null,
  };
  Object.keys(state).forEach((name) => {
    state[name].forEach((experiment, i) => {
      if (experiment.id === ideaId) {
        position.stage = name;
        position.index = i;
        return {};
      }
    });
  });
  return position;
};
