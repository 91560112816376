import makeApolloQuery from '../../utils/queries';
import {
  createIdeaGQL,
  getAllGroupIdeasGQL, quickCreateIdeaGQL,
  updateIdeaAssigneeGQL, updateIdeaDatesGQL,
  updateIdeaIndexGQL,
  updateOrCreateRatingTimeGQL
} from '../../graphql/IdeasAPI';
import groupIdeasByStatus from '../../utils/groupIdeasByStatus';
import debounce from '../../utils/debounce';
import { getIdeaPositionInStateById } from './utils';

export const INITIATE_EXPERIMENTS_STATE = 'INITIATE_EXPERIMENTS_STATE';
export const UPDATE_EXPERIMENT_OPERATIONS_STATE = 'UPDATE_EXPERIMENT_OPERATIONS_STATE';
export const MARK_EXPERIMENT_IS_LOADING = 'MARK_EXPERIMENT_IS_FETCHING';
export const UPDATE_SOME_IDEA_FIELDS = 'UPDATE_SOME_IDEA_FIELDS';
export const UPDATE_EXPERIMENT_STAGES = 'UPDATE_EXPERIMENT_STAGES';
export const UPDATE_TIME_RATING = 'UPDATE_TIME_RATING';

export const UPDATE_IDEA_START_DUE_ASSIGNEE = 'UPDATE_IDEA_START_DUE_ASSIGNEE';
export const UPDATE_EXPERIMENT_ENTIRELY = 'UPDATE_EXPERIMENT_ENTIRELY';
export const UPDATE_IDEA_STATUS = 'UPDATE_IDEA_STATUS';
export const UPDATE_EXPERIMENTS_STAGE = 'UPDATE_EXPERIMENTS_STAGE';
export const CREATE_NEW_EXPERIMENT = 'CREATE_NEW_EXPERIMENT';
export const DELETE_EXPERIMENT = 'DELETE_EXPERIMENT';
export const MOVE_IDEA_TO_KEY_LEARNING = 'MOVE_IDEA_TO_KEY_LEARNING';

//filter
export const SET_FILTER_TAG_VALUES = 'SET_FILTER_TAG_VALUES';
export const SET_FILTER_ASSIGNEE_VALUES = 'SET_FILTER_ASSIGNEE_VALUES';

//quickCreateModal
export const QUICK_MODAL_UPDATE_NEW_EXP_ID = 'QUICK_MODAL_UPDATE_NEW_EXP_ID';

export const setFilterTagsValues = tags => ({
  type: SET_FILTER_TAG_VALUES,
  tags,
});

export const setFilterAssigneeValues = assignee => ({
  type: SET_FILTER_ASSIGNEE_VALUES,
  assignee,
});

export const updateExperimentOperationsState = ({ isLoading, isInitialized, isFetchError }) => ({
  type: UPDATE_EXPERIMENT_OPERATIONS_STATE,
  state: ({ isLoading, isInitialized, isFetchError }),
});


export const markIdeaIsLoading = (idea, isLoading) => ({
  type: MARK_EXPERIMENT_IS_LOADING,
  idea,
  isLoading,
});

export const updateIdeaFieldsRedux = ({ ideaId, fields }) => ({
  type: UPDATE_SOME_IDEA_FIELDS,
  ideaId,
  fields,
});

export const initiateExperimentsState = items => ({
  type: INITIATE_EXPERIMENTS_STATE,
  items,
});

export const updateIdeaAfterPromoteModal = idea => ({
  type: UPDATE_IDEA_START_DUE_ASSIGNEE,
  idea,
});

export const updateExperimentEntirely = idea => ({
  type: UPDATE_EXPERIMENT_ENTIRELY,
  idea,
});


export const moveIdeaToKeyLearning = idea => ({
  type: MOVE_IDEA_TO_KEY_LEARNING,
  idea,
});

export const updateIdeaStatus = (prevStage, nextStage, idea, index) => ({
  type: UPDATE_IDEA_STATUS,
  prevStage,
  nextStage,
  idea,
  index,
});

export const deleteExperiment = idea => ({
  type: DELETE_EXPERIMENT,
  idea,
});

export const checkExperimentsState = user => async (dispatch, getState) => {
  if (user) {
    const state = getState().experiments;

    if (!state.isInitialized) {
      dispatch(updateExperimentOperationsState({ isLoading: true }));
      const result = await makeApolloQuery(getAllGroupIdeasGQL, { teamId: user.team.id });
      dispatch(initiateExperimentsState(groupIdeasByStatus(result.data.allIdeas)));
      dispatch(updateExperimentOperationsState({ isLoading: false, isInitialized: true }));
    }
  }
};

export const updateExperimentStage = (oldStageName, oldStageArray) => ({
  type: UPDATE_EXPERIMENT_STAGES,
  oldStageName,
  oldStageArray,
});

export const checkAndUpdateExperimentsStage = (stageName, stageArray, user) => async (dispatch, getState) => {
  await stageArray.forEach((elem, i) => {
    makeApolloQuery(
      updateIdeaIndexGQL,
      {
        id: elem.id,
        index: i,
        status: elem.status,
        sourceIdeaId: elem.id,
        user,
        idea: elem,
      },
    );
  });
  dispatch(updateExperimentStage(stageName, stageArray));
};

export const updateTimeRating = (ratingTime, ideaId) => ({
  type: UPDATE_TIME_RATING,
  ratingTime,
  ideaId,
});


export const updateTimeRatingOnFrontAndBack = (ratingTime, ideaId, user) => async (dispatch) => {
  if (!ratingTime.id) {
    ratingTime = {
      id: '-1', planTime: 0.0, factTime: 0.0, ideaId, ...ratingTime,
    };
  }
  const updateIdeaRatingTimeInDB = async () => {
    makeApolloQuery(
      updateOrCreateRatingTimeGQL,
      {
        ratingId: ratingTime.id,
        factTime: +ratingTime.factTime,
        planTime: +ratingTime.planTime,
        ideaId,
        userId: user.id,
        teamId: user.team.id,
      },
    );
  };

  const debouncedUpdate = debounce(updateIdeaRatingTimeInDB, 500);
  debouncedUpdate();
  dispatch(updateTimeRating(ratingTime, ideaId));
};

export const updateIdeaAssigneeOnFrontAndBack = (ideaId, assigneeId, user) => async (dispatch) => {
  makeApolloQuery(
    updateIdeaAssigneeGQL,
    {
      ideaId,
      assigneeId,
      userId: user.id,
      teamId: user.team.id,
    }
  );

  const assigneeForFront = user.team.users.find(oneUser => oneUser.id === assigneeId);
  dispatch(updateIdeaFieldsRedux({ ideaId, fields: { assignee: assigneeForFront } }));
};

export const updateIdeaDatesOnFrontAndBack = (ideaId, start, due, user) => async (dispatch) => {
  makeApolloQuery(
    updateIdeaDatesGQL,
    {
      ideaId,
      start,
      due,
      userId: user.id,
      teamId: user.team.id,
    },
  );

  dispatch(updateIdeaFieldsRedux({ ideaId, fields: { start, due } }));
};

export const createNewIdea = (newIdea, user) => async (dispatch, getState) => {
  const state = getState().experiments.items.idea;
  [newIdea, ...state].forEach((elem, i) => {
    makeApolloQuery(
      updateIdeaIndexGQL,
      {
        id: elem.id,
        index: i,
        status: elem.status,
        sourceIdeaId: elem.id,
        user,
        idea: elem,
      },
    );
  });
  dispatch(updateExperimentStage('idea', [newIdea, ...state]));
};

export const quickModalUpdateNewExpId = newExp => ({
  type: QUICK_MODAL_UPDATE_NEW_EXP_ID,
  newExp,
});

export const quickCreateIdeaBackAndFront = (idea, user) => async (dispatch) => {
  dispatch(quickModalUpdateNewExpId(null));
  const { data: { createIdea } } = await makeApolloQuery(quickCreateIdeaGQL, idea);
  dispatch(createNewIdea(createIdea, user));
  dispatch(quickModalUpdateNewExpId(createIdea));
};
