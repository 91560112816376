import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export const fragmentsGrowthMetric = {
  growthMetric: gql`
    fragment growthMetricFields on GrowthMetric {
      id
      label
      updatedAt
      isOMTM
      gaMetricId
      gaViewId
      baselineValue
      baselineDate
      targetValue
      targetDate
      team {
        name
      }
    }
  `,
  growthMetricValue: gql`
    fragment growthMetricValueFields on GrowthMetricValue {
        id
        value
        createdAt
        updatedAt
    }
  `,
};

export const getAllGrowthMetricsClient = gql`
    query getAllGrowthMetricsClient {
      growthMetricsClient @client {
        ...growthMetricFields
        growthMetricValues {
          ...growthMetricValueFields
        }
      }
    }
    ${fragmentsGrowthMetric.growthMetric}
    ${fragmentsGrowthMetric.growthMetricValue}
  `;

export const getGrowthMetricClient = gql`
    query getGrowthMetricClient($label: String!) {
      growthMetricClient(label: $label) @client {
        ...growthMetricFields
        growthMetricValues {
          ...growthMetricValueFields
        }
      }
    }
    ${fragmentsGrowthMetric.growthMetric}
    ${fragmentsGrowthMetric.growthMetricValue}
  `;

export const getGrowthMetricClientById = gql`
    query getGrowthMetricClientByID($id: ID!){
      growthMetricClientById(id: $id) @client {
        ...growthMetricFields
        growthMetricValues {
          ...growthMetricValueFields
        }
      }
    }
    ${fragmentsGrowthMetric.growthMetric}
    ${fragmentsGrowthMetric.growthMetricValue}
  `;

export const growthMetricClientByIdGraphQL = graphql(getGrowthMetricClientById, {
  options: ({ metric }) => ({ variables: { id: metric.id } }),
  props: ({ data: { growthMetricClientById, loading } }) => ({
    growthMetric: growthMetricClientById,
    loading,
  }),
});

export const getAllGrowthMetricsClientGraphQL = graphql(getAllGrowthMetricsClient, {
  props: ({ data: { growthMetricsClient } }) => ({
    growthMetrics: growthMetricsClient,
  }),
});

export const getAllGrowthMetrics = gql`
    query allGrowthMetrics($teamId: ID!) {
      allGrowthMetrics(filter: {team: {id: $teamId}}) {
        ...growthMetricFields
        growthMetricValues {
          ...growthMetricValueFields
        }
      }
    }
    ${fragmentsGrowthMetric.growthMetric}
    ${fragmentsGrowthMetric.growthMetricValue}
  `;

export const getAllGrowthMetricsGraphQL = graphql(getAllGrowthMetrics, {
  options: ({ user }) => ({ variables: { teamId: user.team.id } }),
  props: ({ data: { loading, allGrowthMetrics } }) => ({
    growthMetrics: allGrowthMetrics,
    growthMetricLoading: loading,
  }),
});

export const createGrowthMetricGraphQL = graphql(
  gql`
    mutation createGrowthMetric(
      $teamId: ID!
      $label: String!
      $isOMTM: Boolean
    ) {
      createGrowthMetric(
        label: $label
        teamId: $teamId
        isOMTM: $isOMTM
      ) {
        ...growthMetricFields
      }
    }
    ${fragmentsGrowthMetric.growthMetric}
  `,
  { name: 'createGrowthMetric' },
);

export const removeGrowthMetricGraphQL = graphql(
  gql`
    mutation removeGrowthMetric($id: ID!) {
      deleteGrowthMetric(id: $id) {
        id
      }
    }
  `,
  { name: 'removeGrowthMetric' },
);

export const updateGrowthMetricGraphQL = graphql(
  gql`
    mutation updateGrowthMetric($id: ID!, $label: String! , $isOMTM: Boolean) {
      updateGrowthMetric(id: $id, label: $label, isOMTM: $isOMTM) {
        ...growthMetricFields
      }
    }
    ${fragmentsGrowthMetric.growthMetric}
  `,
  { name: 'updateGrowthMetric' },
);

export const updateGrowthMetricReturnIdGraphQL = graphql(
  gql`
    mutation updateGrowthMetric($id: ID!, $label: String! , $isOMTM: Boolean) {
      updateGrowthMetric(id: $id, label: $label, isOMTM: $isOMTM) {
        id
      }
    }
  `,
  { name: 'updateGrowthMetricReturnId' },
);

export const createGrowthMetricValueGraphQL = graphql(
  gql`
    mutation createGrowthMetricValue(
    $growthMetricId: ID!
    $value: Int!
  ){
    createGrowthMetricValue(
      growthMetricId: $growthMetricId
      value: $value
    ) {
      ...growthMetricValueFields
    }
  }
  ${fragmentsGrowthMetric.growthMetricValue}
  `, { name: 'createGrowthMetricValue' },
);

export const removeGrowthMetricValueGraphQL = graphql(
  gql`
    mutation removeGrowthMetricValue($id: ID!) {
      deleteGrowthMetricValue(id: $id) {
        id
      }
    }
  `,
  { name: 'removeGrowthMetricValue' },
);

export const updateGrowthMetricValueGraphQL = graphql(
  gql`
    mutation updateGrowthMetricValue($id: ID!, $value: Int!) {
      updateGrowthMetricValue(id: $id, value: $value) {
        id
      }
    }
  `,
  { name: 'updateGrowthMetricValue' },
);

export const updateBaselineDate = graphql(
  gql`
    mutation updateBaselineDate(
      $baselineDate: DateTime
      $metricId: ID!
    ) {
      updateGrowthMetric(
        baselineDate: $baselineDate
        id: $metricId
      ) {
        id
      }
    }
  `, { name: 'updateBaselineDate' },
);

export const updateBaselineValue = graphql(
  gql`
    mutation updateBaselineValue(
      $baselineValue: Int
      $metricId: ID!
    ) {
      updateGrowthMetric(
        baselineValue: $baselineValue
        id: $metricId
      ) {
        id
      }
    }
  `, { name: 'updateBaselineValue' },
);

export const updateTargetValue = graphql(
  gql`
    mutation updateTargetValue(
      $targetValue: Int
      $metricId: ID!
    ) {
      updateGrowthMetric(
        targetValue: $targetValue
        id: $metricId
      ) {
        id
      }
    }
  `, { name: 'updateTargetValue' },
);

export const updateTargetDate = graphql(
  gql`
    mutation updateTargetDate(
      $targetDate: DateTime
      $metricId: ID!
    ) {
      updateGrowthMetric(
        targetDate: $targetDate
        id: $metricId
      ) {
        id
      }
    }
  `, { name: 'updateTargetDate' },
);

export const disconnectGAMetricGraphQL = graphql(
  gql`
    mutation disconnectMetricsFromGA($metricID : ID!) {
      updateGrowthMetric(
        id: $metricID
        gaAccessToken: null
        gaViewId: null
        gaMetricId: null
        gaStartDate: null
        gaRefreshToken: null
      ) {
        id
      }
    }
  `, { name: 'disconnectGAMetric' },
);
