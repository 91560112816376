import React from 'react';
import PropTypes from 'prop-types';
import './Preloader.scss';

const Preloader = ({
  height, color, hSize, wSize,
}) => {
  const spinnerClass = `spinner-border ${color}`;
  return (
    <div className="Preloader" style={{ height: `${height}vh` }}>
      <div
        className={spinnerClass}
        role="status"
        style={{
          height: `${hSize}rem`,
          width: `${wSize}rem`,
        }}
      >
        <span className="sr-only"> Loading...</span>
      </div>
    </div>
  );
};

Preloader.propTypes = {
  height: PropTypes.any,
  hSize: PropTypes.number,
  wSize: PropTypes.number,
  color: PropTypes.string,
};

Preloader.defaultProps = {
  height: 74,
  wSize: 2,
  hSize: 2,
  color: 'text-dark',
};

export default Preloader;
