import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import Loadable from 'react-loadable';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import logger from 'redux-logger';

import * as serviceWorker from './serviceWorker';
import Preloader from './components/Preloader/Preloader';
import rootReducer from './reducers/reducers';
import history from './utils/history';


import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/shards-dashboards.scss';
import { client } from './utils/apolloClient';

const Application = Loadable({
  loader: () => import(/* webpackChunkName: 'Application' */ './components/Application/Application.apollo'),
  loading: Preloader,
});


const initialState = {};
const enhancer = composeWithDevTools(
  applyMiddleware(
    thunkMiddleware,
    logger,
  ),
);

const store = createStore(rootReducer, initialState, enhancer);

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={client}>
      <DndProvider backend={HTML5Backend}>
        <Application history={history} />
      </DndProvider>
    </ApolloProvider>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
