export const STAGE_NAMES = {
  Brainstorm: 'Brainstorm',
  Prioritize: 'Prioritize',
  Top_rated: 'Top rated',
  Experiment: 'Experiment',
  Analyze: 'Analyze',
  Learn: 'Learn',
};

export const dataBaseStatusesFlow = [
  'idea',
  'plan',
  'test',
  'analysis',
  'learn',
];

export const getStageOptions = [
  {
    name: STAGE_NAMES.Brainstorm, // column name
    route: '/experiments/brainstorm', // URL
    stage: 'idea', // stage is used to show modal when Card is dropped if needed
    status: 'idea', // what status this stage has in database,
    filter: ideas => ideas.filter(() => true), // "filter" используется в groupIdeasByStatus
    isActive: location => location === '/experiments/brainstorm', // is this stage active now (used in pipeline)
    pipelineView: true, // should we show it in pipeline
    pipelineDataCy: 'experiments-idea--page', // used for cypress
    columnView: true, // should we show it in column view
  },
  {
    name: STAGE_NAMES.Prioritize,
    route: '/experiments/plan?ice-rating',
    stage: 'plan',
    status: 'plan',
    filter: ideas => ideas.filter(() => true),
    isActive: (location, params) => location === '/experiments/plan' && params.has('ice-rating'),
    pipelineView: true,
    pipelineDataCy: 'experiments-ice-rating--page',
    columnView: true,
  },
  {
    name: STAGE_NAMES.Top_rated,
    route: '/experiments/plan?top-rated',
    stage: 'plan',
    status: 'plan',
    filter: ideas => ideas.filter(() => true),
    isActive: (location, params) => location === '/experiments/plan' && params.has('top-rated'),
    pipelineView: true,
    pipelineDataCy: 'experiments-top-rated--page',
    columnView: false,
  },
  {
    name: STAGE_NAMES.Experiment,
    route: '/experiments/test',
    stage: 'test',
    status: 'test',
    filter: ideas => ideas.filter(() => true),
    isActive: location => location === '/experiments/test',
    pipelineView: true,
    pipelineDataCy: 'experiments-test--page',
    columnView: true,
  },
  {
    name: STAGE_NAMES.Analyze,
    route: '/experiments/analysis',
    stage: 'analysis',
    status: 'analysis',
    filter: ideas => ideas.filter(() => true),
    isActive: location => location === '/experiments/analysis',
    pipelineView: true,
    pipelineDataCy: 'experiments-analysis--page',
    columnView: true,
  },
  {
    name: STAGE_NAMES.Learn,
    route: '/experiments/learn',
    stage: 'learn',
    status: 'learn',
    filter: ideas => ideas.filter(() => true),
    isActive: location => location === '/experiments/learn',
    pipelineView: false,
    pipelineDataCy: '',
    columnView: true,
  },
];
