import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { fragmentsGrowthMetric } from './GrowthMetricAPI';

export const fragmentsCheckList = {
  checkList: gql`
    fragment checkListFields on CheckList {
      id
      label
    }
  `,
  checkItem: gql`
    fragment checkItemFields on CheckItem {
      id
      label
      isDone
    }
  `,
};

export const getCheckListsQuery = gql`
  query getCheckLists($ideaId: ID!) {
    allCheckLists(filter: {
      idea: {
        id: $ideaId
      }
    }) {
      id
      label
      checkItems {
        id
        isDone
        label
        user {
          id
          email
          profile {
            id
            avatar
          }
        }
      }
    }
  }
`;

export const getCheckListsQL = graphql(
  getCheckListsQuery,
  {
    options: props => ({
      variables: { ideaId: props.ideaId },
      fetchPolicy: 'cache-and-network',
    }),
    props: ({ data: { allCheckLists: checkLists, loading } }) => ({ loading, checkLists }),
  },
);

export const refetchGetCheckListsClient = gql`
  mutation createCheckList(
    $id: ID!
  ) {
    createCheckList(
      id: $id
    ) @client {
      id
    }
  }
`;

export const createCheckListQL = graphql(
  gql`
    mutation createCheckList(
      $label: String!
      $idea: ID!
    ) {
      createCheckList(
        label: $label
        ideaId: $idea
      ) {
        id
      }
    }
  `,
  { name: 'createCheckList' },
);

export const updateCheckListGL = graphql(
  gql`
    mutation updateCheckList(
      $id: ID!
      $label: String!
    ) {
      updateCheckList(
        id: $id
        label : $label
      ) {
        id
      }
    }
  `,
  { name: 'updateCheckList' },
);

export const deleteCheckListGL = graphql(
  gql`
    mutation deleteCheckList($id: ID!) {
      deleteCheckList(id: $id) {
        id
      }
    }
  `,
  { name: 'deleteCheckList' },
);


export const createCheckItemQL = graphql(
  gql`
    mutation createCheckItem(
      $label: String!
      $checkListId: ID!
    ) {
      createCheckItem(
        label: $label
        checkListId: $checkListId
      ) {
        id
      }
    }
  `,
  { name: 'createCheckItem' },
);

export const updateCheckItemQL = graphql(
  gql`
    mutation updateCheckItem(
      $id: ID!
      $label: String!
      $isDone: Boolean
      $assigneeId: ID
    ) {
      updateCheckItem(
        id: $id
        label: $label
        userId: $assigneeId
        isDone: $isDone
      ) {
        id
        user {
          email
        }
      }
    }
  `,
  { name: 'updateCheckItem' },
);

export const deleteCheckItemGL = graphql(
  gql`
    mutation deleteCheckItem($id: ID!) {
      deleteCheckItem(id: $id) {
        id
      }
    }
  `,
  { name: 'deleteCheckItem' },
);
