export const LAYOUT_TYPES = {
  DEFAULT: 'DEFAULT',
  HEADER_NAVIGATION: 'HEADER_NAVIGATION',
  ICON_SIDEBAR: 'ICON_SIDEBAR',
};


export const valuesRange = [1, 2, 3, 4, 5];

export const pageRange = 2;
