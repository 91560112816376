const globalDebounceTimer = new Map();

export default function debounce(f, ms = 300) {
  return (...args) => {
    const onComplete = () => {
      console.log('debounce resolve', f);
      f.apply(f, args);
      globalDebounceTimer.delete(f);
    };
    if (globalDebounceTimer.has(f)) {
      console.log('debounce reject', f);
      clearTimeout(globalDebounceTimer.get(f));
    }
    globalDebounceTimer.set(f, window.setTimeout(onComplete, ms));
  };
}
