import { getStageOptions } from '../data/ideaStatus';

const groupIdeasByStatus = (allIdeas) => {
  const result = {
  };
  getStageOptions.forEach(({ status }) => {
    result[status] = [];
  });
  allIdeas
    .forEach((idea) => {
      result[idea.status].push(idea.index
        ? { ...idea, stage: idea.status }
        : { ...idea, index: 0, stage: idea.status });
    });
  return result;
};

export default groupIdeasByStatus;
