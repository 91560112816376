import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

export const fragmentsCustomerPersona = {
  question: gql`
    fragment questionFields on Question {
        question
        id
        variants
        inputType
        icon
        label
    }
  `,
  answer: gql`
    fragment answerFields on Answer {
      answer
      id
    }
  `,
};

// the cache-and-network is needed here because we updating answers in parallel
export const getAllQuestionsGraphQl = graphql(
  gql`
    query getAllQuestions {
      allQuestions {
        ...questionFields
      }
    }
    ${fragmentsCustomerPersona.question}
  `, {
    options: { fetchPolicy: 'cache-and-network' },
    props: ({ data: { loading, allQuestions } }) => ({ allQuestions, loading }),
  },
);

export const getAllPersonsGraphQl = graphql(
  gql`
    query getAllPersons($teamId: ID!) {
      allPersonae(
        filter: { team: { id: $teamId } }
        orderBy: createdAt_DESC
      ) {
        id
        answers {
          ...answerFields
          questions {
            ...questionFields
          }
        }
      }
    }
    ${fragmentsCustomerPersona.question}
    ${fragmentsCustomerPersona.answer}
  `, {
    options: props => ({
      variables: { teamId: props.user.team.id },
    }),
    props: ({ data: { loading, allPersonae } }) => ({ personae: allPersonae, loading }),
  },
);

export const createPersonaGraphQl = graphql(
  gql`
    mutation createPersona($teamId: ID!) {
      createPersona(teamId: $teamId) {
        id
        answers {
           ...answerFields
          questions {
            ...questionFields
          }
        }
      }
    }
    ${fragmentsCustomerPersona.question}
    ${fragmentsCustomerPersona.answer}
  `,
  { name: 'createPersona' },
);

export const createAnswerGraphQl = graphql(
  gql`
    mutation createAnswer(
      $personaId: ID!
      $questionsId: ID!
      $answer: [String!]!
    ) {
      createAnswer(
        personaId: $personaId
        questionsId: $questionsId
        answer: $answer
      ) {
        id
        answer
      }
    }
  `,
  { name: 'createAnswer' },
);

export const deletePersonaGraphQl = graphql(
  gql`
    mutation deletePersona($id: ID!) {
      deletePersona(id: $id) {
        id
      }
    }
  `,
  { name: 'deletePersona' },
);

export const deleteAnswerGraphQl = graphql(
  gql`
    mutation deleteAnswer($id: ID!) {
      deleteAnswer(id: $id) {
        id
      }
    }
  `,
  { name: 'deleteAnswer' },
);

export const updateAnswerGraphQl = graphql(
  gql`
    mutation updateAnswer($id: ID!, $answer: [String!]!) {
      updateAnswer(id: $id, answer: $answer) {
        id
      }
    }
  `,
  { name: 'updateAnswer' },
);
