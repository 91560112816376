export default {
  development: {
    uri: 'https://localhost:4000/graphql',
    sentry: null,
    client_id: '0oCgstA1Eajpuv401KQ2uU602ICdMnA2',
    auth0_domain: 'growthhub.eu.auth0.com',
    redirectUrl: 'https://localhost:3000/oauth/callback',
    ga: {
      clientId: '508197994469-5mfvtps8k3ge478cam563t9tlvvj4v8k.apps.googleusercontent.com',
      apiKey: 'AIzaSyBPlkayKvzgxGNTaAzRwEA0YneoCn2XDCw',
      scope: 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/analytics.readonly',
    },
    slack: {
      oauthUrl: 'https://slack.com/api/oauth.access',
      client_id: '186676247077.723694672870',
      client_secret: '478abe8842c7438c9f74355552fddcb6',
    },
  },
  production: {
    uri: 'https://api.growthhub.io/graphql',
    sentry: 'https://d68c6cd2552140cb8d8f488c1edabaa4@sentry.io/1484022',
    client_id: '0oCgstA1Eajpuv401KQ2uU602ICdMnA2',
    auth0_domain: 'growthhub.eu.auth0.com',
    redirectUrl: 'https://app.growthhub.io/oauth/callback',
    ga: {
      clientId: '508197994469-5mfvtps8k3ge478cam563t9tlvvj4v8k.apps.googleusercontent.com',
      apiKey: 'AIzaSyBPlkayKvzgxGNTaAzRwEA0YneoCn2XDCw',
      scope: 'https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/analytics.readonly',
    },
    slack: {
      oauthUrl: 'https://slack.com/api/oauth.access',
      client_id: '186676247077.723694672870',
      client_secret: '478abe8842c7438c9f74355552fddcb6',
    },
  },
}[process.env.NODE_ENV];
