/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
import produce from 'immer';
import {
  INITIATE_EXPERIMENTS_STATE,
  UPDATE_EXPERIMENT_OPERATIONS_STATE,
  CREATE_NEW_EXPERIMENT,
  DELETE_EXPERIMENT,
  UPDATE_IDEA_STATUS,
  UPDATE_EXPERIMENT_ENTIRELY,
  MOVE_IDEA_TO_KEY_LEARNING,
  UPDATE_IDEA_START_DUE_ASSIGNEE,
  MARK_EXPERIMENT_IS_LOADING,
  UPDATE_SOME_IDEA_FIELDS,
  UPDATE_EXPERIMENT_STAGES,
  UPDATE_TIME_RATING,
  SET_FILTER_TAG_VALUES,
  SET_FILTER_ASSIGNEE_VALUES, QUICK_MODAL_UPDATE_NEW_EXP_ID
} from './actions';
import { getIdeaPositionInStateById } from './utils';

export const initialExperimentsState = {
  quickCreateModal: {
    lastCreatedExp: null,
  },
  items: {
    idea: [],
    plan: [],
    test: [],
    analysis: [],
    learn: [],
  },
  filter: {
    assignee: null,
    tags: [],
  },
  isLoading: true,
  isInitialized: false,
  isFetchError: false,
};

const experiments = produce((state = initialExperimentsState, action) => {
  let index = null;
  let stage = null;
  if ((action.idea && action.idea.id) || action.ideaId) {
    const id = (action.idea && action.idea.id) ? action.idea.id : action.ideaId;
    const res = getIdeaPositionInStateById(state.items, id);
    index = res.index;
    stage = res.stage;
  }
  switch (action.type) {
    case UPDATE_EXPERIMENT_OPERATIONS_STATE:
      if (typeof action.state.isLoading !== 'undefined') state.isLoading = action.state.isLoading;
      if (typeof action.state.isInitialized !== 'undefined') state.isInitialized = action.state.isInitialized;
      if (typeof action.state.isFetchError !== 'undefined') state.isFetchError = action.state.isFetchError;
      return;
    case INITIATE_EXPERIMENTS_STATE:
      state.items = action.items;
      return;
    case CREATE_NEW_EXPERIMENT:
      state.items.idea.unshift(action.idea);
      return;
    case DELETE_EXPERIMENT:
      state.items[action.idea.status] = state.items[action.idea.status].filter(idea => idea.id !== action.idea.id);
      return;
    case UPDATE_IDEA_STATUS:
      state.items[action.prevStage].splice(index, 1);
      state.items[action.nextStage].splice(action.index, 0, {...action.idea, status: action.nextStage });
      return;
    case UPDATE_IDEA_START_DUE_ASSIGNEE:
      state.items[stage][index] = {
        ...state.items[stage][index],
        start: action.idea.start,
        due: action.idea.due,
        assignee: action.idea.assignee,
      };
      return;
    case UPDATE_EXPERIMENT_ENTIRELY:
      state.items[stage][index] = action.idea;
      return;
    case MOVE_IDEA_TO_KEY_LEARNING:
      state.items[stage].splice(index, 1);
      state.items[stage] = [...state.items[stage]];
      state.items.learn.unshift(action.idea);
      return;
    case MARK_EXPERIMENT_IS_LOADING:
      state.items[stage][index].isLoading = action.isLoading;
      return;
    case UPDATE_SOME_IDEA_FIELDS:
      Object.keys(action.fields).forEach((name) => {
        state.items[stage][index][name] = action.fields[name];
      });
      return;
    case UPDATE_EXPERIMENT_STAGES:
      state.items[action.oldStageName] = action.oldStageArray;
      return;
    case UPDATE_TIME_RATING:
      state.items[stage][index].ratingTime = action.ratingTime;
      return;
    case SET_FILTER_TAG_VALUES:
      state.filter.tags = action.tags;
      return;
    case SET_FILTER_ASSIGNEE_VALUES:
      state.filter.assignee = action.assignee;
      return;
    case QUICK_MODAL_UPDATE_NEW_EXP_ID:
      state.quickCreateModal.lastCreatedExp = action.newExp;
      return;
    default:
      return state;
  }
});

export default experiments;
